<template>
  <v-container class="ed-carousel-item__content">
    <v-layout row wrap>
      <v-flex
        xs12
        sm6
        align-content-center
        justify-center
        class="pr-5 pl-5 d-flex"
      >
        <v-img
          :src="pic"
          width="100%"
          height="auto"
          style="max-width: 400px"
          aspect-ratio="1"
          contain
        />
      </v-flex>
      <v-flex xs12 md6>
        <h1>
          {{ title }}
        </h1>
        <p v-html="content"></p>
        <v-btn
          @click="redirect(callToActionTarget)"
          color="white"
          round depressed large
          v-if="callToActionTarget && callToAction"
        >
          {{callToAction}}
        </v-btn>

        <v-container class="ed-mobilewrapper">
          <v-layout row wrap>
            <v-flex v-if="showAppsBtns" d-flex>
              <v-img @click="redirect('https://itunes.apple.com/fr/app/ticket-cesu/id662122346')"
                style="cursor:pointer;"
                src="/img/app-store@3x.png"
                height="100px"
                contain
              />
              <v-img @click="redirect('https://play.google.com/store/apps/details?id=fr.edenred.cesu')"
                style="cursor:pointer;"
                src="/img/google-play@3x.png"
                height="100px"
                contain
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'CarouselItemContent',
  props: {
    pic: String,
    title: String,
    content: String,
    callToAction: String,
    callToActionTarget: String,
    showAppsBtns: Boolean
  },
  methods: {
    redirect (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>

  @import '@/styles/_colors.scss';

  * { color: white; }
  @for $i from 0 through 6 {
    h#{$i} {
      font-size: 2.5em;
      font-weight: bolder;
    }
  }
  p {
    font-size: 1.33em;
    margin: 30px 0px;
  }
  :root {
    button , * /deep/ button, & /deep/ button, * /deep/ a {
      color: #333;
      font-weight: bolder;
      color: $blue;
    }
  }

  @media all and (max-width: 768px) {
    .ed-mobilewrapper {
      padding: 0px !important;
    }
    @for $i from 0 through 6 {
      h#{$i} {
        font-size: 1.25em;
        font-weight: bolder;
      }
    }
    p {
      font-size: 1em;
      margin: 15px 0px;
    }

    :root {
      button , * /deep/ button, & /deep/ button, * /deep/ a {
        font-size: .75em;
        width: 100%;
      }
    }

    .ed-carousel-item__content {
      padding-top: 0;
      margin-top: 0;
    }
  }

</style>
