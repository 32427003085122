<template>
  <v-footer color="primary" height="auto">
      <v-container grid-list-md text-xs-center>
        <v-layout row wrap>
          <v-flex xs12 md2 class="pr-4 pl-4">
            <span class="white--text">
              <v-layout align-content-center justify-center>
                <v-flex xs6 md12 class="pr-4 pl-4">
                  <img
                    src="/img/logo_edenred_white.png"
                    alt="logo white"
                    width="100%"
                    height="auto"
                  />
                </v-flex>
              </v-layout>
            </span>
          </v-flex>

          <v-layout xs12 md7 wrap=false style="margin: 0;">
            <v-flex xs12 md3 text-md-left>
              <FooterTitle>
                <h2 class="ed-footer__title">Vous êtes</h2>
              </FooterTitle>
              <ul>
                <li><a target="_blank" href="https://www.ticket-cesu.fr/decideurs/entreprises/Pages/qu-est-ce-que-ticket-cesu.aspx">Décideur</a></li>
                <li><a target="_blank" href="https://www.ticket-cesu.fr/intervenants/Pages/vous-etes-un-organisme.aspx">Intervenant</a></li>
                <li><a target="_blank" href="https://www.ticket-cesu.fr/beneficiaires/Pages/les-avantages-pour-vous.aspx">Bénéficiaire</a></li>
              </ul>
            </v-flex>
            <v-flex xs12 md3 text-md-left>
              <FooterTitle>
                <h2 class="ed-footer__title">Information</h2>
              </FooterTitle>
              <ul>
                <li><a target="_blank" href="https://www.ticket-cesu.fr/Pages/qui-sommes-nous.aspx">Nous connaitre</a></li>
                <li><a target="_blank" href="https://www.ticket-cesu.fr/Pages/faq-decideurs.aspx">FAQ</a></li>
                <li><a target="_blank" href="https://www.ticket-cesu.fr/Pages/Contact.aspx">Nous contacter</a></li>
                <li><a href="https://click.edenred.fr/Documents_Espace_Client/Juridique/GDPR/politique_protection_des_donnees.pdf" target="_blank">Protection des données</a></li>
                <li><a href="" class="optanon-toggle-display">Gérer mes préférences Cookies</a> </li>
              </ul>
            </v-flex>
            <v-flex xs12 md3 text-md-left>
              <FooterTitle>
                <h2 class="ed-footer__title">À propos</h2>
              </FooterTitle>
              <ul>
                <li><a target="_blank" href="https://www.ticket-cesu.fr/Pages/faq-decideurs.aspx">Aide</a></li>
                <li><a target="_blank" href="https://click.edenred.fr/Documents_Espace_Client/VELCESU/cgv.pdf">Tarifs et CGV</a></li>
                <li><a target="_blank" href="https://www.ticket-cesu.fr/Pages/mentions-legales.aspx">Mentions Légales</a></li>
              </ul>
            </v-flex>
            <v-flex xs12 md3 text-md-left>
              <FooterTitle>
                <h2 class="ed-footer__title">Nos produits</h2>
              </FooterTitle>
              <ul>
                <li><a target="_blank" href="https://www.ticketrestaurant.fr/">Ticket Restaurant&#174;</a></li>
                <li><a target="_blank" href="https://www.kadeos.fr/">Ticket Kadéos&#174;</a></li>
                <li><a target="_blank" href="https://www.ticket-clean-way.fr/">Ticket Clean Way&#174;</a></li>
              </ul>
            </v-flex>
          </v-layout>

 

          <!-- Social + copyright -->
          <v-flex xs12 row wrap>
            <SocialLinks style="margin: 40px 0px 10px 0px"/>
            <p>Copyright Edenred {{ (new Date()).getFullYear() }} </p>
          </v-flex>
        </v-layout>
      </v-container>
  </v-footer>
</template>

<script>

import FooterTitle from './FooterTitle'
import SocialLinks from './SocialLinks'

export default {
  name: 'TheFooter',
  components: {
    FooterTitle,
    SocialLinks
  }
}
</script>

<style lang="scss" scoped>
  * {
    color: white
  }
  ul {
    padding: 0px;
    li {
      list-style: none;
      padding: 5px 0px;
      a {
        text-decoration: none;
        font-size: 0.9em;
      }
    }
  }
</style>
