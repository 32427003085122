<template>
  <v-container text-xs-center>
    <v-layout column align-center justify-center>
      <v-flex
        class="ed-howitworks__header"
        xs12 md6
      >
        <h2>Comment ça marche ?</h2>
        <p>
          Rien de plus simple, en quelques clics vous pouvez commander, puis payer vos services à la personne.
        </p>
      </v-flex>
    </v-layout>
    <v-container grid-list-xl>

        <!-- Blocks for > md -->
        <v-layout hidden-sm-and-down row wrap>
          <HowItWorksBlock
            v-for="(block, id) in blocks"
            :content="Object.assign({id}, block)"
            :key="id"

          />
        </v-layout>

        <!-- Slide blocks for < md -->
        <v-layout hidden-md-and-up row wrap >
          <HowItWorksCarousel/>
        </v-layout>

    </v-container>
  </v-container>
</template>

<script>

import HowItWorksBlock from '@/components/HowItWorksBlock.vue'
import HowItWorksCarousel from '@/components/HowItWorksCarousel.vue'
import blocksJson from '@/content/howitworks.json'

export default {
  name: 'HowItWorks',
  components: {
    HowItWorksCarousel,
    HowItWorksBlock
  },
  data () {
    return {
      blocks: blocksJson
    }
  }
}
</script>

<style lang="scss" scoped>

  @import '@/styles/_colors.scss';

  :root {
    @for $i from 1 through 6 {
      h#{$i} {
        font-size: 3em;
        font-weight: bolder;
      }
      @media all and (max-width: 768px) {
        h#{$i} {
          font-size: 1.5em;
        }
      }
    }
    * {
      color: white;
    }
  }

  .ed-howitworks {
    &__header {
      max-width: 25px;
      p {
        font-size: 1.33em
      }
    }
  }
</style>
