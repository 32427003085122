<template>
  <v-container grid-list-md text-md-center text-sm-left>
    <v-layout row wrap>
      <v-layout hidden-sm-and-down xs12 sm6>
        <v-flex
          xs12
          align-content-center
          justify-center
          class="pr-5 pl-5 d-flex"
        >
          <v-img
            src="/img/img-allo.png"
            width="100%"
            height="auto"
            style="max-width: 400px"
            aspect-ratio="1"
            contain
          />
        </v-flex>
      </v-layout>
      <v-flex xs12 sm6>
        <h3 class="ed-contact__title">Allo Ticket CESU</h3>
        <p class="ed-contact__content mt-4 mb-4">
          Un service dédié disponible du <strong>lundi</strong>
          au <strong>vendredi</strong> de <strong>8h30</strong>
          à <strong>18h30</strong> pour répondre à toutes vos questions.
        </p>
        <v-layout row wrap class="pa-0" text-xs-center>
          <v-flex class="ed-contact__phone" xs12 sm6>
            <p class="ed-contact__phone-label">Pour commander</p>
            <v-btn class="ed-contact__phone-number" outline round large xs12 block href="tel:0821232425">
              <v-icon class="ed-contact__phone-number-icon">fa-phone</v-icon>
              0 821 23 24 25
            </v-btn>
          </v-flex>
          <v-flex class="ed-contact__phone" xs12 sm6>
            <p class="ed-contact__phone-label">Pour utiliser vos titres</p>
            <v-btn class="ed-contact__phone-number" outline round large block href="tel:0821610610">
              <v-icon class="ed-contact__phone-number-icon">fa-phone</v-icon>
              0 821 610 610
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ContactBlock'
}
</script>

<style lang="scss" scoped>
  @import '../styles/_colors';

  .ed-contact {
    &__title {
      color: $blue;
      text-align: left;
    }

    &__content {
      text-align: left;

      strong {
        color: $blue;
      }
    }

    &__phone {
      position: relative;
    }

    &__phone-label {
      color: $blue;
      font-weight: bold;
    }

    &__phone-number {
      color: $blue;
      font-size: 1.5em;
      padding-left: 22px;
      padding-right: 22px;
    }

    &__phone-number-icon {
      position: absolute;
      left: 0;
      font-size: 1.33em !important;
    }
  }

  @for $i from 0 through 6 {
    h#{$i} {
      font-size: 3em;
      font-weight: bolder;
    }
    @media all and (max-width: 768px) {
      h#{$i} {
        font-size: 2em;
        font-weight: bolder;
      }
    }
  }
  p {
    font-size: 1.5em;
  }
  a, button {
    font-weight: bolder;
    font-size: 2em;
    i {
      margin-right: 10px;
    }
  }

</style>
