<template>
  <Carousel style="height: 320px;" hidecontrols :shiftcontrols="true" :greencontrols="true">
    <CarouselItem v-for="(block, id) in blocks" :key="id">
      <AdvantagesBlock
        inCarousel="true"
        :block="Object.assign({id},block)"
        :interval="10000000000"
        :cycle="false"
      />
    </CarouselItem>
  </Carousel>
</template>

<script>

import Carousel from '@/components/Carousel'
import CarouselItem from '@/components/CarouselItem'
import AdvantagesBlock from '@/components/AdvantagesBlock'

export default {
  name: 'AdvantagesCarousel',
  components: {
    Carousel,
    CarouselItem,
    AdvantagesBlock
  },
  props: {
    blocks: Array
  }
}
</script>

<style lang="scss" scoped>
</style>
