<template>
  <v-container
    grid-list-md text-md-center text-sm-left
    class="ed-profileselection"
    :class="classActiveTab"
  >
    <v-layout row wrap class="ed-profileselection__layout">
      <!-- IMAGE CONTAINER -->
      <v-flex
        xs12
        sm6
        align-start
        justify-center
        class="pr-5 pl-5 d-flex"
      >
        <v-img
          :src="fullpictoshow"
          width="100%"
          height="auto"
          style="max-width: 400px"
          aspect-ratio="1"
          contain
        />
      </v-flex>

      <!-- TABS CONTAINER -->
      <v-flex xs12 md6>
        <p class="ed-select-profile-text">
          Sélectionner votre profil
        </p>
        <v-tabs
          v-model="active"
          color="transparent"
          slider-color="transparent"
        >
          <v-tab
            v-for="(profile, id) in profiles"
            :key="id"
            :ripple="false"
          >
            <v-img
              :src="profile.pic"
              width="50px"
              height="50px"
              aspect-ratio="1"
              contain
            />
            <svg
              :style="svgPosition"
              v-if="id === 0"
              xmlns="http://www.w3.org/2000/svg"
              width="108" height="76" viewBox="0 0 108 76"
              id="ed-svgindicator"
            >
                <g fill="none">
                  <g fill="#399" class="ed-svgindicator__filling">
                    <path id="ed-svgindicator__path" d="M108 76L0.5 76 0.5 37.5 0.5 35C0.5 15.7 15.7 0 35 0 54.3 0 69.5 15.7 69.5 35L69.5 37.5C69.5 58.8 86.7 76 108 76Z"/>
                  </g>
                </g>
              </svg>
          </v-tab>
          <v-tab-item
            v-for="(profile, id) in profiles"
            :key="id"
          >
            <v-flex d-flex class="ed-profileselection__block pa-3">
              <span class="ed-profileselection__fullname">
                {{profile.fullname}}
              </span>
              <span class="ed-profileselection__jobtitle">
                Profession : {{profile.job_title}}
              </span>
            </v-flex>
          </v-tab-item>
        </v-tabs>

        <!--
          JOB DESC
          -> only shown when showjobdesc === true
          -> if showjobdesc != true then we show disclaimer
        -->
        <v-flex v-if="showjobdesc" class="ed-profileselection__jobdesc pa-3">
          <p v-html="jobdesctoshow"></p>
        </v-flex>

        <v-flex v-if="!showjobdesc" class="ed-profileselection__advantages pa-3">
          <v-flex hidden-sm-and-down>
            <p v-html="disclaimers.discover_advantages" hidden-sm-and-down></p>
          </v-flex>
          <v-btn round large depressed color="white" @click="scrollToSecondInstance">
            {{disclaimers.call_to_action}}
          </v-btn>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import jsonProfiles from '@/content/profiles.json'
import anime from 'animejs'

const svgLeftD = 'M108 76L0.5 76 0.5 37.5 0.5 35C0.5 15.7 15.7 0 35 0 54.3 0 69.5 15.7 69.5 35L69.5 37.5C69.5 58.8 86.7 76 108 76Z'
// const svgMdlD = 'M146,76L0,76,C21.3,76,38.5,58.8,38.5,37.5,L38.5,35,C38.5,15.7,53.7,0,73,0,C92.3,0,107.5,15.7,107.5,35,L107.5,37.5,C107.5,58.8,124.7,76,146,76Z'

export default {
  name: 'ProfileSelection',
  created () {
    this.$eventBus.$on('ed-select-profile', (activetab) => {
      this.active = activetab
      this.$emit('tabChanged', activetab)
    })
  },
  data () {
    const fullpictoshow = this.showjobdesc ? jsonProfiles.profiles[0].full_pic_light : jsonProfiles.profiles[0].full_pic
    return {
      active: 0,
      profiles: jsonProfiles.profiles,
      disclaimers: jsonProfiles.disclaimers,
      jobdesctoshow: jsonProfiles.profiles[0].job_description,
      fullpictoshow
    }
  },
  computed: {
    classActiveTab () {
      return {
        'ed-profileselection--jobdescshown': this.showjobdesc,
        'ed-profileselection--activetab0': this.active === 0
      }
    },
    svgPosition () {
      if (this.active > 0) {
        anime({
          targets: '#ed-svgindicator__path',
          d: svgLeftD,
          elasticity: 0,
          easing: 'easeOutExpo'
        })
      } else {
        anime({
          targets: '#ed-svgindicator__path',
          d: svgLeftD,
          elasticity: 0,
          easing: 'easeOutExpo'
        })
      }
      anime({
        targets: '#ed-svgindicator',
        translateX: `${this.active * 64}px`,
        elasticity: 0,
        easing: 'easeOutExpo'
      })
      return this.emitProfileChangeEvent(this.active)
    }
  },
  watch: {
    // We watch the active tab in order to toggle jobdesc
    active (val) {
      if (val !== null) {
        if (this.showjobdesc) {
          this.jobdesctoshow = this.profiles[val].job_description
          this.fullpictoshow = this.profiles[val].full_pic_light
        } else {
          this.fullpictoshow = this.profiles[val].full_pic
        }
      }
    }
  },
  props: {
    showjobdesc: Boolean
  },
  methods: {
    emitProfileChangeEvent () {
      this.$eventBus.$emit('ed-select-profile', this.active)
    },
    scrollToSecondInstance () {
      // Offset helped
      function getOffset (el) {
        const rect = el.getBoundingClientRect()
        return {
          left: rect.left + window.scrollX,
          top: rect.top + window.scrollY
        }
      }

      const instances = document.getElementsByClassName('ed-profileselection')
      if (instances.length > 1 && typeof this.$vuetify !== 'undefined') {
        const intanceToScrollTo = instances[instances.length - 1]
        const instanceScrollPos = getOffset(intanceToScrollTo)
        const instanceHeight = intanceToScrollTo.offsetHeight
        this.$vuetify.goTo((instanceScrollPos.top - instanceHeight / 2))
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  @import '@/styles/_colors.scss';

  * /deep/ .v-tabs__items {
    background: $green__light !important;
    border-radius: 8px;
  }

  * /deep/ .v-tabs__container {
    height: auto;
  }

  * /deep/ .v-tabs__item {
    padding: 0px 7px 10px 7px;
    overflow: visible;
    margin-top: 10px;
  }

  * /deep/ svg {
    position: absolute;
    bottom: -4px;
    left: -5px;
    z-index: -1;
    height: 100%;
    // transform: translate3d(-26%,0,0);
  }

  .ed-select-profile-text {
    text-align: left;
    margin-bottom: 4px;
    color: white;
    font-weight: bold;

    @media all and (max-width: 768px) {
      display: none;
    }
  }

  .ed-profileselection {
    &__layout {
      padding-right: 30px;
    }
    &__block {
      color: white;
      flex-direction: column;
    }
    &__fullname , &__jobtitle {
      margin: 0px;
      padding: 0px;
      text-align: left;
    }
    &__fullname {
      font-size: 3em;
      font-weight: bolder;
    }
    &__jobtitle {
      font-size: 1.7em;
      font-weight: lighter;
    }
    &__jobdesc {
      font-size: 1.33em;
      & /deep/ strong , * /deep/ strong {
        color: $green__variant;
      }
      text-align: left;
    }
    &__advantages {
      p , * /deep/ p, & /deep/ p {
        color: white;
        font-size: 1.4em;
        text-align: left;
      }
      button , * /deep/ button, & /deep/ button, a , * /deep/ a, & /deep/ a {
        color: $blue;
        font-weight: bolder;
        @media all and (max-width: 768px) {
          width: 100%;
        }
      }
    }

    @media all and (max-width: 768px) {
      margin: 0px !important;
      padding: 0px;
      &__fullname {
        font-size: 1.5em;
      }
      &__jobtitle {
        font-size: 1.2em;
      }
      &__jobdesc {
        font-size: 1em;
      }
      &__advantages {
        padding: 5px 10px !important;
      }
    }

    // Color adjustements if showing job desc
    &--jobdescshown {

        $lightGreen: transparentize($green__light, 0.8) !important;

        .ed-svgindicator__filling {
          fill: $lightGreen;
        }

        * /deep/ .v-tabs__items {
          background: $lightGreen;
          border-radius: 8px;
        }
        .ed-profileselection {
          &__fullname {
            color: $blue;
          }
          &__jobtitle {
            color: $green__variant
          }
        }
    }

    &--activetab0 {
        * /deep/ .v-tabs__items {
          border-top-left-radius: 0px;
        }
    }
  }
</style>
