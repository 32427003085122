<template>
  <v-flex
    xs12 md6
    :class="{
      'incarousel': inCarousel,
      'ed-advantagesblock__wrapper': true
    }"
  >
    <div class="ed-advantagesblock__block elevation-12 pa-3" :class="{'active': block.active}">
      <h3>{{block.title}}</h3>
      <p>
        {{block.content}}
      </p>
    </div>
  </v-flex>
</template>

<script>
export default {
  name: 'AdvantagesBlock',
  props: {
    block: Object,
    inCarousel: false
  }
}
</script>

<style lang="scss" scoped>

  @import '@/styles/_colors.scss';

  .ed-advantagesblock {
    &__block {
      border-radius: 8px;
      transition: all ease-in-out 0.25s;
      height: 100%;
      background: white;
      color: $blue;
      @for $i from 0 through 6 {
        h#{$i} {
          font-size: 1.5em;
          text-transform: uppercase;
          margin-bottom: 15px;
        }
      }
      p {
        font-size: 1.15em;
      }
      &.active {
        color: white;
        background: $green;
      }
    }
    &__wrapper {
      position: relative;
      z-index: 10;
    }
  }

  // shift first two elements to the left and last two
  // to the right
  * >>> .ed-advantagesblock {
    &__wrapper:not(.incarousel) {
      &:nth-child(-n+3) {
        .ed-advantagesblock__block {
          transform: translate3d(-12.5%, 0, 0);
        }
      }
      &:nth-child(n+3) {
        .ed-advantagesblock__block {
          transform: translate3d(12.5%, 0, 0);
        }
      }
    }
  }

</style>
