<template>
    <v-content>
      <div id="top-anchor"></div>
      <v-layout
        justify-center
        align-center
        column
      >

        <!-- Carousel -->
        <blockWrapper withgreenbackground>
          <v-container>
            <v-flex xs12>
              <HomeCarousel/>
            </v-flex>
          </v-container>
        </blockWrapper>

        <!-- Advantages // Pro -->
        <blockWrapper withseparator>
          <Advantages
            :header="advPro.header"
            :blocks="advPro.blocks"
            :pic="advPro.pic"
            :showNote="true"
          />
        </blockWrapper>

        <!-- Advantages // Pro -->
        <blockWrapper>
          <Advantages
            :header="advCusto.header"
            :blocks="advCusto.blocks"
            :pic="advCusto.pic"
            reverse
          />
        </blockWrapper>

        <!-- How does it work ? -->
        <blockWrapper withseparator withgreenbackground>
          <HowItWorks/>
        </blockWrapper>

        <!-- Profiles -->
        <BlockWrapper withseparator>
          <v-container>
            <v-layout>
              <v-flex xs12>
                <ProfileSelection v-on:tabChanged="tabChanged" showjobdesc/>
              </v-flex>
            </v-layout>
          </v-container>
        </BlockWrapper>

        <!-- Tables switch -->
        <BlockWrapper withgreengradient>
          <v-container fluid fill-height>
            <TableTabs :currentTab="currentTab"/>
          </v-container>
        </BlockWrapper>

        <!-- Contact us block -->
        <BlockWrapper withseparator>
          <v-container fluid fill-height>
            <ContactBlock/>
          </v-container>
        </BlockWrapper>

      </v-layout>
    </v-content>
</template>

<script>

import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'
import ContactBlock from '@/components/ContactBlock.vue'
import TableTabs from '@/components/TableTabs.vue'
import BlockWrapper from '@/components/BlockWrapper.vue'
import HowItWorks from '@/components/HowItWorks.vue'
import Advantages from '@/components/Advantages.vue'
import HomeCarousel from '@/components/HomeCarousel.vue'
import ProfileSelection from '@/components/ProfileSelection.vue'

// load content
import jsonAdvantages from '@/content/advantages.json'

export default {
  name: 'Home',
  components: {
    TheHeader,
    TheFooter,
    ContactBlock,
    TableTabs,
    BlockWrapper,
    HowItWorks,
    Advantages,
    HomeCarousel,
    ProfileSelection
  },
  data () {
    return {
      advPro: jsonAdvantages.professionals,
      advCusto: jsonAdvantages.customers,
      currentTab: 0
    }
  },
  methods: {
    tabChanged (tab) {
      this.currentTab = tab
    }
  }
}
</script>
