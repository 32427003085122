<template>
  <v-container>
    <v-layout row wrap  v-bind="{ [`reverse`]: reverse }">
      <v-flex xs12 md7>
        <v-flex xs12 class="ed-advantagesblock__header">
          <h2>{{header.title}}</h2>
          <p>{{header.subtitle}}</p>
        </v-flex>

        <v-container grid-list-xl>
          <!-- Desktop -->
          <v-layout row wrap hidden-sm-and-down>
            <AdvantagesBlock v-for="(block, id) in blocks" :key="id" :block="block"/>
          </v-layout>

          <div v-if="showNote" style="width:35rem;margin:auto;font-size:0.8em;">
            * dans la limite de 1830€ par an dans le cadre de la législation des services à la personne
          </div>
          <!-- Mobile -->
          <v-layout hidden-md-and-up row wrap class="ed-advantagesblock__carouselwrapper">
            <AdvantagesCarousel
              class="carousel"
              :blocks="blocks"
            />
            <v-flex
              class="pic"
              xs12 align-content-center justify-center d-flex
            >
              <v-img
                :src="pic"
                width="100%"
                height="auto"
                aspect-ratio="1"
                contain
              />
            </v-flex>
          </v-layout>

        </v-container>
      </v-flex>

      <v-layout hidden-sm-and-down>
        <v-flex xs12 align-content-center justify-center d-flex>
          <v-img
            :src="pic"
            width="100%"
            height="auto"
            aspect-ratio="1"
            contain
          />
        </v-flex>
      </v-layout>

    </v-layout>
  </v-container>
</template>

<script>

import AdvantagesBlock from '@/components/AdvantagesBlock'
import AdvantagesCarousel from '@/components/AdvantagesCarousel'

export default {
  name: 'Advantages',
  components: {
    AdvantagesBlock,
    AdvantagesCarousel
  },
  props: {
    pic: String,
    header: Object,
    blocks: Array,
    reverse: Boolean,
    showNote: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>

  @import '@/styles/_colors.scss';

  .ed-advantagesblock {
    &__header {
      @for $i from 0 through 6 {
        h#{$i} {
          font-size: 3em;
          color: $blue;
          margin-bottom: 10px;
        }
      }
      p {
        font-size: 1.33em;
      }
      @media all and (max-width: 768px) {
        margin-bottom: 33.33%;
        @for $i from 0 through 6 {
          h#{$i} {
            font-size: 1.5em;

          }
        }
        p {
          font-size: 1.1em;
        }
      }
    }
    &__carouselwrapper {
      .carousel {
        z-index: 10;
      }
      .pic {
        position: absolute;
        width: 80%;
        margin-left: 10%;
        z-index: 1;
        transform: translate3d(0,-50%,0);
      }
    }
  }
</style>
