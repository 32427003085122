<template>
  <v-layout row wrap :reverse="alignLeft === true">
      <v-flex xs12 md8>
        <v-card class="pa-5 ed-tablectn">
          <v-data-table
            :headers="table.headers"
            :items="table.rows"
            hide-actions
          >
            <template slot="headers" slot-scope="props">
              <th v-html="header.text" v-for="header in props.headers" :key="header.text"></th>
            </template>
            <template slot="items" slot-scope="props">
              <td
                :class="{'text-xs-center' : id > 0}"
                v-for="(item, id) in props.item"
                :key="id"
                :style="id === 0 ? `text-align: left;` : ''"
              >
                {{ item }}
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-flex xs12 md4 class="ed-backgroundblock" :class="{ 'ed-backgroundblock ed-backgroundblock--reverse': alignLeft }">
        <p class="ed-backgroundblock__title">{{ title }}</p>
        <span class="ed-separator__rounded ed-separator__big"></span>
        <p class="ed-backgroundblock__content">
          {{ description }}
        </p>
      </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'TableViewer',
  props: {
    table: Object,
    title: String,
    description: String,
    alignLeft: Boolean
  }
}
</script>

<style lang="scss">

  @import '../styles/_colors.scss';

  table.v-table {
    border: none !important;
  }

  table.v-table thead th {
    font-weight: bolder;
    color: $blue !important;
    font-size: 1.4em
  }

  table.v-table tbody td {
    font-size: 1.05em !important;
    text-align: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  table.v-table tbody tr {
    &:nth-child(odd) {
      background: transparentize($green, 0.9) !important;
    }
    &:not(:last-child) {
      border-bottom: 1px solid transparentize($green, 0.7) !important;
    }
    &:last-child * {
      font-weight: bold !important;
    }
  }
</style>

<style lang="scss" scoped>

  @import '../styles/_colors.scss';
  @import '../styles/_gradients.scss';

  $blockOffset: 35px;
  $blockOffsetPa: 15px;
  $blocksRadius: 10px;

  .ed-tablectn, .ed-backgroundblock {
    border-radius: $blocksRadius;
  }

  .ed-tablectn {
    position: relative;
    z-index: 2;
    transform: translate3d(0, $blockOffset / 2, 0);
  }

  .ed-backgroundblock {
    color: white;
    z-index: 1;
    padding: $blockOffset + $blockOffsetPa ($blockOffset + $blockOffsetPa) * 1.25;
    transform: translate3d(-$blockOffset, -$blockOffset / 2, 0);
    @include diagonal-gradient($green, #{transparentize($green, 0.3)}, $green, #{transparentize($green, 0.3)});
    height: 100%;
    &__title {
      font-size: 3em;
      font-weight: bolder;
      position: relative;
      width: 100%;
      margin: 0px;
      padding: 0px;
    }
    &__content {
      font-size: 2em;
      font-weight: lighter;
      margin-top: 15px;
    }

    &--reverse {
      transform: translate3d($blockOffset, -$blockOffset / 2, 0);
    }
  }

  @media all and (max-width: 768px) {
    .ed-tablectn, .ed-backgroundblock {
      transform: translate3d(0,0,0) !important;
    }
    .ed-tablectn {
      padding: 0px !important;
    }
    .ed-backgroundblock {
      margin-top: 25px;
    }
  }
</style>
