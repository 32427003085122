<template>
  <v-carousel-item>
    <v-container fill-height justify-center align-items-center pa-0 ma-0 style="width: 90%; margin-left: 5% !important;">
      <v-layout row wrap fill-height align-items-center>
        <v-flex fill-height justify-center align-items-center d-flex>
          <slot></slot>
        </v-flex>
      </v-layout>
    </v-container>
  </v-carousel-item>
</template>

<script>
export default {
  name: 'CarouselItem'
}
</script>
