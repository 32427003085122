import { render, staticRenderFns } from "./BlockWrapper.vue?vue&type=template&id=2506db5f&scoped=true&"
import script from "./BlockWrapper.vue?vue&type=script&lang=js&"
export * from "./BlockWrapper.vue?vue&type=script&lang=js&"
import style0 from "./BlockWrapper.vue?vue&type=style&index=0&id=2506db5f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2506db5f",
  null
  
)

component.options.__file = "BlockWrapper.vue"
export default component.exports