<template>
  <ul>
    <li
      v-for="social in socialMedias"
      :key="social.name"
      :class="{mini: mini}"
    >
      <v-btn @click="redirect(social.link)" target="_blank" fab small color="primary" depressed>
        <v-icon>{{social.icon}}</v-icon>
      </v-btn>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'SocialLinks',
  data () {
    return {
      socialMedias: [
        {
          title: 'linkedin',
          icon: 'fa-linkedin',
          link: 'https://www.linkedin.com/company/edenred-france/'
        },
        {
          title: 'twitter',
          icon: 'fa-twitter',
          link: 'https://twitter.com/EdenredFrance'
        },
        {
          title: 'facebook',
          icon: 'fa-facebook',
          link: 'https://www.facebook.com/EdenredFr/'
        },
        {
          title: 'youtube',
          icon: 'fa-youtube',
          link: 'https://www.youtube.com/user/EdenredFrance'
        }
      ]
    }
  },
  props: {
    color: String,
    mini: Boolean
  },
  methods: {
    redirect (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/_colors.scss';

  button {
    background: $green__light !important;
  }
  ul {
    margin: 0px;
    padding: 0px;
    li {
      display: inline-block;
      padding: 0px 5px;
      a {
        color: white;
      }
    }
  }

  // Overwright sized for mini btn
  .mini {
    padding: 0px 1px;
    button {
      height: 20px;
      width: 20px;
      background: white !important;
      text-align: center;
      margin: 25% 3px;
      i {
        font-size: 10px !important;
        margin-right: 0px !important;
        color: $green__variant !important;
      }
    }
  }
</style>
