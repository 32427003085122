import { render, staticRenderFns } from "./CarouselItemContent.vue?vue&type=template&id=60192268&scoped=true&"
import script from "./CarouselItemContent.vue?vue&type=script&lang=js&"
export * from "./CarouselItemContent.vue?vue&type=script&lang=js&"
import style0 from "./CarouselItemContent.vue?vue&type=style&index=0&id=60192268&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60192268",
  null
  
)

component.options.__file = "CarouselItemContent.vue"
export default component.exports