import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'

// Vuetify
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
import router from './router'

// Use vuetify and customize theme
Vue.use(Vuetify, {
  theme: {
    primary: {
      base: '#1c6e6d',
      lighten1: '#409a95'
    },
    secondary: '#b0bec5',
    accent: '#8c9eff',
    error: '#b71c1c'
  }
})

Vue.config.productionTip = false

// We create an eventBus for linking the two instances
// of profile sliders
// This would benefit from a vuex refacto later on
Vue.prototype.$eventBus = new Vue()

new Vue({
  render: h => h(App),
  router,
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app')
