<template>
  <div class="ed-tabletabs">
    <v-tabs
      v-model="active"
      color="transparent"
      centered
      class="ed-tabs"
    >
      <v-tab ripple>
        Avantage financeur
      </v-tab>
      <v-tab ripple>
        Avantage utilisateur
      </v-tab>
      <v-tab-item>
        <TableViewer :title="currentTab === 0 ? '5,50 %' : '11,19 %'" :description="`D'économies sur votre impôt`" :table="financeurs"/>
      </v-tab-item>
      <v-tab-item>
        <TableViewer :title="'+ de 50 %'" :description="`D'économies grâce au Ticket CESU`" :table="utilisateurs" :alignLeft="true"/>
      </v-tab-item>
    </v-tabs>

    <p class="ed-tabs__notice">
    * : Bénéfice net imposable déduction faite des 1 830 euros de Ticket CESU commandés<br/>
    ** : calculé à partit du barème 2017<br/>
    *** : 25% du montant des Tickets CESU commandés</p>
  </div>
</template>

<script>
import TableViewer from '@/components/TableViewer'
import jsonTable from '@/content/tables.json'

export default {
  name: 'TableTabs',
  components: {
    TableViewer
  },
  data () {
    return {
      active: 0,
      financersTable: jsonTable.financeurs,
      usersTable: jsonTable.utilisateurs
    }
  },
  props: {
    table: Object,
    currentTab: {
      type: Number,
      default: 0
    }
  },
  computed: {
    financeurs () {
      return jsonTable.financeurs[this.currentTab]
    },
    utilisateurs () {
      return jsonTable.utilisateurs[this.currentTab]
    }
  },
  methods: {
    next () {
      const active = parseInt(this.active)
      this.active = (active < 2 ? active + 1 : 0)
    }
  }
}
</script>

<!--
  /!\ UNSCOPPED
We use unscoped css here in order to overwright
styling of tabs components from Vuetify
-->
<style lang="scss">

  @import '../styles/_gradients';
  @import '../styles/_colors';

  .ed-tabs {
    width: 100%;

    &__notice {
      align-self: flex-start;
      padding-left: 70px;
      font-size: 0.9em;
    }
  }
  .ed-tabletabs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    thead tr {
      border: none !important;
    }
    .v-tabs__div {
      max-width: none;
      width: 300px;
      font-size: 0.9em !important;
    }
    .v-tabs__item {
      color: $blue;
      font-size: 1.5em;
      text-transform: capitalize;
      opacity: 1 !important;
      font-weight: lighter;
      &--active {
        font-weight: bold;
        // font-weight: bolder;
        @include diagonal-gradient(
          #{opacify($color: white, $amount: 0.8)},
          rgba(28, 110, 109, .2),
          #{opacify($color: white, $amount: 0.8)},
          rgba(28, 110, 109, .4)
        );
      }
    }
    .v-tabs__slider, .v-tabs__slider.accent {
      @include linear-gradient($green, transparent);
    }
    .v-tabs__content {
      padding: 40px 15px;
      width: 100%;
    }
    .v-tabs__container {
      width: 100%;
      flex-wrap: nowrap;
    }

    @media all and (max-width: 768px) {
      .v-tabs__item {
        font-size: 1.25em;
      }
      .v-table thead th {
        font-size: 1em;
        padding: 0 10px !important;
        span {
          display: block;
          width: 100%;
        }
      }
      table.v-table tbody td:first-child , table.v-table tbody td:not(:first-child) {
        padding-left: 5px;
        padding-right: 5px;
        font-size: 1em !important;
      }
    }
  }
</style>
