<template>
  <v-carousel
    next-icon="ed-arrow-right"
    prev-icon="ed-arrow-left"
    delimiter-icon="ed-carousel__delimiter"
    :interval="interval"
    :hide-controls="hidecontrols"
    :class="{
      'shiftcontrols': shiftcontrols,
      'greencontrols': greencontrols
    }"
    :cycle="false"
  >

    <slot></slot>

  </v-carousel>
</template>

<script>
export default {
  name: 'Carousel',
  props: {
    interval: 5000,
    hidecontrols: false,
    shiftcontrols: false,
    greencontrols: false,
    cycle: false
  }
}
</script>

<!--
  /!\ unscoped for deep targetting
-->
<style lang="scss">

  @import '@/styles/_colors.scss';

  .v-carousel {
    box-shadow: none;
    border: none;
    @media all and (max-width: 768px) {
      .v-carousel__prev , .v-carousel__next {
        top: 25%;
      }
    }
    &.shiftcontrols {
      overflow: visible;
      .v-carousel__controls {
        transform: translate3d(0,50%,0);
      }
      &.greencontrols {
        .ed-carousel__delimiter {
          background: $green !important;
        }
      }
    }
  }
  .v-carousel__controls {
    background: transparent;
  }
  .ed-carousel__delimiter {
    width: 30px;
    height: 4px;
    background: white;
    display: inline-block;
    opacity: 1;
    border-radius: 2px;
  }
  %edArrow {
    content: '';
    display: inline-block;
    width: 35px;
    height: 25px;
    border-radius: 15px;
  }

  %edArrowBig {
    @media all and (min-width: 768px) {
      width: 70px;
      height: 55px;
      border-radius: 35px;
      background-size: 30px 30px !important;
    }
  }

  .ed-arrow-right {
    @extend %edArrow;
    @extend %edArrowBig;
    background: url('/img/icons/arrow-left@2x.png');
    background-color: $green__light;;
    background-position: center center;
    background-size: 15px 15px;
    background-repeat: no-repeat;

  }
  .ed-arrow-left {
    @extend %edArrow;
    @extend %edArrowBig;
    background: url('/img/icons/arrow-right@2x.png');
    background-color: $green__light;
    background-position: center center;
    background-size: 15px 15px;
    background-repeat: no-repeat;
  }

</style>
