<template>
  <Carousel style="height: 320px;" hidecontrols :shiftcontrols="true">
    <CarouselItem v-for="(block, id) in blocks" :key="id">
      <HowItWorksBlock
        :content="Object.assign({id},block)"
      />
    </CarouselItem>
  </Carousel>
</template>

<script>

import Carousel from '@/components/Carousel'
import CarouselItem from '@/components/CarouselItem'
import HowItWorksBlock from '@/components/HowItWorksBlock'
import blocksJson from '@/content/howitworks.json'

export default {
  name: 'HowItWorksCarousel',
  components: {
    Carousel,
    CarouselItem,
    HowItWorksBlock
  },
  data () {
    return {
      blocks: blocksJson
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
