<template>
  <div class="er-footertitle">
    <slot/>
  </div>
</template>

<!-- FooterTitle declaration -->
<script>
export default {
  name: 'FooterTitle'
}
</script>

<!-- FooterTitle styling -->
<style lang="scss" scoped>

  @import '../styles/_colors.scss';

  .er-footertitle {
    position: relative;
    &:after {
      content: '';
      height: 4px;
      background: white;
      width: 30px;
      border-radius: 2px;
      display: inline-block;
      margin: 5px 0px 10px 0px;
      opacity: 0.3;
    }
    @for $i from 0 through 6 {
      h#{$i} {
        text-transform: uppercase;
        font-size: 1em;
      }
    }
    * {
      color: white;
    }
  }
</style>
