<template>
  <v-system-bar
    color="rgba(28, 110, 109, 1)"
    class="ed-systembar"
    window
  >
    <v-container class="pa-1">
      <v-layout row wrap style="align-items: center;">
        <a href="https://www.edenred.fr/" target="_blank" style="margin-bottom: 0px;text-decoration:none;">EDENRED FRANCE</a>
        <v-spacer></v-spacer>
        <SocialLinks mini/>
      </v-layout>
    </v-container>
  </v-system-bar>
</template>

<script>

import SocialLinks from './SocialLinks.vue'

export default {
  name: 'TheSystemBar',
  components: {
    SocialLinks
  }
}
</script>

<style lang="scss" scoped>
  .ed-systembar {
    height: auto !important;
    * {
      color:white;
    }
  }
</style>
