<template>
  <div class="ed-theheader">
    <v-flex hidden-sm-and-down>
      <TheSystemBar />
    </v-flex>
    <v-toolbar
      color="white"
      flat
      height="70px"
      class="ed-theheader__wrapper"
    >
      <v-container
        class="pa-0 ma-auto"
        d-flex
        style="height: 70px;"
      >
        <v-layout row wrap>
          <v-flex xs4 d-flex align-items-start pt-2 pb-2>
            <v-img
              src="/img/logo_cesu.png"
              height="100%"
              srcset="/img/logo_cesu.png 2x"
              alt="Logo Edenred"
              id="logo"
              contain
              start
              aspect-ratio="1"
              position="left center"
            />
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs8 d-flex align-center>
            <v-layout row justify-end>
              <v-btn @click="redirect('https://vel.edenred.fr/?master=ace')" class="ed-button-client" round xs6 flat>Déjà client</v-btn>
              <v-btn @click="redirect('https://vel.edenred.fr/?master=ace')" class="ed-button-order" round xs6 flat color="white">Commander</v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-toolbar>
  </div>
</template>

<script>
import TheSystemBar from './TheSystemBar.vue'

export default {
  name: 'TheHeader',
  components: {
    TheSystemBar
  },
  methods: {
    redirect (url) {
      window.location.href = url
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/_colors.scss';

  .ed-theheader {
    display: inline-block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #ececec;
    z-index: 1000;
  }

  .ed-button-client {
    color: $blue !important;
    font-weight: bold;
    font-size: 0.9em !important;
    border: solid 2px $blue;
    text-transform: initial;
  }
  .ed-button-order {
    background-color: $blue !important;
    font-weight: bold;
    font-size: 0.9em !important;
    text-transform: initial;
  }

  @media all and (max-width: 768px) {

    $menuHeightMobile: 60px;

    .ed-theheader {
      &__wrapper {
        height: $menuHeightMobile;
        .container , /deep/ .container {
          height: $menuHeightMobile !important;
          position: absolute;
          top: 0;
          left: 0;
          padding: 0px 10px !important;
        }
      }
      * /deep/ button , /deep/ button, * /deep/ a , /deep/ a, a, button {
        width: 45%;
        min-width: 0px;
        font-size: 0.75em;
        margin: 0px;
        padding: 0px;
        &:first-child {
          margin-right: 2.5%;
        }
      }
    }
    // #logo {
    //   width: 100%;
    //   height: auto;
    // }
  }

  @media all and (min-width: 768px) {
    .ed-theheader {
      *, * /deep/ * {
        flex-basis: auto;
      }
    }
  }

</style>
