<template>
  <v-carousel
    :cycle="false"
    next-icon="ed-arrow-left"
    prev-icon="ed-arrow-right"
    delimiter-icon="ed-carousel__delimiter"
  >
    <CarouselItem v-for="(citem, id) in carouselItems.slice(0,1)" :key="`carouselitem-` + id">
      <CarouselItemContent
        :pic="citem.pic"
        :title="citem.title"
        :content="citem.content"
        :callToAction="citem.call_to_action"
        :callToActionTarget="citem.href"
        :showAppsBtns="citem.show_apps_block"
      />
    </CarouselItem>

    <CarouselItem>
      <ProfileSelection/>
    </CarouselItem>

    <CarouselItem v-for="(citem, id) in carouselItems.slice(1,carouselItems.length)" :key="id">
      <CarouselItemContent
        :pic="citem.pic"
        :title="citem.title"
        :content="citem.content"
        :callToAction="citem.call_to_action"
        :callToActionTarget="citem.href"
        :showAppsBtns="citem.show_apps_block"
      />
    </CarouselItem>
  </v-carousel>
</template>

<script>

import Carousel from '@/components/Carousel.vue'
import CarouselItem from '@/components/CarouselItem.vue'
import CarouselItemContent from '@/components/CarouselItemContent.vue'
import ProfileSelection from '@/components/ProfileSelection.vue'
import jsonCarouselItems from '@/content/carousel.json'

export default {
  name: 'HomeCarousel',
  components: {
    Carousel,
    CarouselItem,
    ProfileSelection,
    CarouselItemContent
  },
  data () {
    return {
      carouselItems: jsonCarouselItems
    }
  }
}
</script>

<!--
  /!\ unscoped for deep targetting
-->
<style lang="scss">
  .v-carousel {
    box-shadow: none;
    border: none;
  }
  .v-carousel__controls {
    background: transparent;
  }
  .ed-carousel__delimiter {
    width: 30px;
    height: 4px;
    background: white;
    display: inline-block;
    opacity: 1;
    border-radius: 2px;
  }
</style>
