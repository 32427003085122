import { render, staticRenderFns } from "./FooterTitle.vue?vue&type=template&id=77e84752&scoped=true&"
import script from "./FooterTitle.vue?vue&type=script&lang=js&"
export * from "./FooterTitle.vue?vue&type=script&lang=js&"
import style0 from "./FooterTitle.vue?vue&type=style&index=0&id=77e84752&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77e84752",
  null
  
)

component.options.__file = "FooterTitle.vue"
export default component.exports