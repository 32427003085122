import { render, staticRenderFns } from "./HowItWorksBlock.vue?vue&type=template&id=20751bf8&scoped=true&"
import script from "./HowItWorksBlock.vue?vue&type=script&lang=js&"
export * from "./HowItWorksBlock.vue?vue&type=script&lang=js&"
import style0 from "./HowItWorksBlock.vue?vue&type=style&index=0&id=20751bf8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20751bf8",
  null
  
)

component.options.__file = "HowItWorksBlock.vue"
export default component.exports