<template>
  <!-- Blocks for > md -->
  <v-flex xs12 sm6 md3>
    <div class="ed-howitworks__block text-md-left elevation-12">
      <h3>
        <span>0{{content.id + 1}}</span> <span class="ed-howitworks__title">{{ content.title }}</span>
      </h3>
      <p>
        {{ content.content }}
      </p>
      <a class="ed-howitworks__link">
        <v-icon>fa-arrow-right</v-icon>
        <span class="ed-howitworks__link-text" @click="redirect(content.button_link)">{{ content.button }}</span>
      </a>
    </div>
  </v-flex>
</template>

<script>
export default {
  name: 'HowItWorksBlock',
  props: {
    content: Object
  },
  methods: {
    redirect (url) {
      if (url.substring(0, 1) === '#') {
        const elements = document.querySelectorAll('.v-carousel__controls__item')
        if (elements) {
          console.log('elements : ', elements[0])
          elements[2].click()
        }
        window.scrollTo(0, 0)
      } else {
        window.location.href = url
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  @import '@/styles/_colors.scss';

  :root {
    @for $i from 1 through 6 {
      h#{$i} {
        font-size: 3em;
        font-weight: bolder;
      }
    }
    * {
      color: white;
    }
  }

  .ed-howitworks {
    &__title {
      text-transform: uppercase;
      font-size: 0.66em !important;
      margin-left: 15px;
    }
    &__link {
      display: flex;
      align-items: center;
    }
    &__link-text {
      font-weight: bold;
      font-size: 1.2em !important;
    }
    &__block {
      background: $green__light;
      padding: 25px;
      border-radius: 10px;
      height: 100%;
      display: flex;
      flex-direction: column;
      @for $i from 0 through 6 {
        h#{$i} {
          font-size: 2em;
          @media all and (max-width: 768px) {
            font-size: 1.5em;
          }
        }
      }
      span {
        font-size: 150%;
        @media all and (max-width: 768px) {
          font-size: 110%;
        }
      }
      p {
        height: 100%;
      }
      a {
        i {
          font-size: 15px;
          margin-right: 5px;
        }
      }
    }
  }
</style>
