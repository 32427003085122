<template>
  <div
    class="ed-blockwrapper"
    :class="{
      'greengradient' : withgreengradient,
      'greenbackground' : withgreenbackground
    }"
  >
    <div
      v-if="withseparator"
      class="ed-blockwrapper__separator"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BlockWrapper',
  props: {
    withseparator: Boolean,
    withgreengradient: Boolean,
    withgreenbackground: Boolean
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/_colors.scss';
  @import '@/styles/_gradients.scss';

  .ed-blockwrapper {
    position: relative;
    padding: 25px 0px;
    width: 100%;
    &__separator {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate3d(-50%,-50%, 0);
      z-index: 20;
      span {
        display: inline-block;
        height: 50px;
        width: 7px;
        background: $green__light;
        margin: 0px 5px;
        border-radius: 4px;
        &:nth-child(2) {
          transform: translate3d(0,10px, 0);
        }
      }
    }
    &.greengradient {
      @include linear-gradient(
        transparent,
        #{transparentize($green,0.9)}
      );
    }
    &.greenbackground {
      background: $green;
    }
  }
</style>
