import { render, staticRenderFns } from "./AdvantagesCarousel.vue?vue&type=template&id=8100e858&scoped=true&"
import script from "./AdvantagesCarousel.vue?vue&type=script&lang=js&"
export * from "./AdvantagesCarousel.vue?vue&type=script&lang=js&"
import style0 from "./AdvantagesCarousel.vue?vue&type=style&index=0&id=8100e858&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8100e858",
  null
  
)

component.options.__file = "AdvantagesCarousel.vue"
export default component.exports