<template>
  <v-app>
    <TheHeader/>
      <router-view/>
    <TheFooter/>
  </v-app>
</template>

<script>

import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'
import './styles/styles.scss'

export default {
  name: 'app',
  components: {
    TheHeader,
    TheFooter
  }
}
</script>

<style lang="scss">
  .application--wrap {
    background: #fff !important;
  }
</style>

<style lang="scss" scoped>
  :root {
    padding-top: 60px;
    @media all and (min-width: 768px) {
      padding-top: 100px;
      }
  }
</style>
